<script setup lang="ts">
import { submitForm } from '@formkit/core'
import type { BookAnAppointmentSidebarProps } from './BookAnAppointmentSidebar.props'
import BookAnAppointmentBrandSelection, {
  type BrandSelectionFormType,
} from './BookAnAppointmentBrandSelection.vue'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { ts } = useI18n()

const props = withDefaults(defineProps<BookAnAppointmentSidebarProps>(), {
  sideSlideId: SIDEBAR_IDS.bookAnAppointmentSidebar,
  needBrandSelection: false,
})

const { closeDialog, isDialogOpen } = useDialog(props.sideSlideId)

const BOOK_AN_APPOINTMENT_FORM_ID = 'BOOK_AN_APPOINTMENT_FORM'
const BOOK_AN_APPOINTMENT_BRAND_FORM_ID = 'BOOK_AN_APPOINTMENT_BRAND_FORM'

const brandSelectionForm = ref<BrandSelectionFormType>({
  brand: undefined,
})
const step = ref<number>(1)

if (props.needBrandSelection) step.value = 0

const stepsObj = ref([
  {
    label: ts('bookAnAppointment.buttons.bookAppointment'),
    primaryClickHandle: () => {
      submitForm(BOOK_AN_APPOINTMENT_BRAND_FORM_ID)
    },
  },
  {
    label: ts('bookAnAppointment.buttons.send'),
    primaryClickHandle: () => {
      submitForm(BOOK_AN_APPOINTMENT_FORM_ID)
    },
  },
  {
    label: ts('bookAnAppointment.buttons.close'),
    primaryClickHandle: closeDialog,
  },
])

const handleStepProgression = () => step.value++

watch(isDialogOpen, newVal => {
  if (newVal) {
    if (props.needBrandSelection) {
      step.value = 0
    } else {
      step.value = 1
    }
  }
})
</script>

<template>
  <div>
    <OrganismsSidebarSlide
      :id="sideSlideId"
      :header-props="{
        titleText: $ts('bookAnAppointment.title'),
      }"
      :footer-props="{
        primaryText: stepsObj[step].label,
      }"
      :is-alpha-layer-transparent="isNested"
      @primary-click="stepsObj[step].primaryClickHandle"
    >
      <template #body>
        <BookAnAppointmentBrandSelection
          v-if="step === 0"
          :brand-selection-form="brandSelectionForm"
          @submit-brand-form="handleStepProgression"
        />
        <BookAnAppointmentForm
          v-if="step === 1"
          :brand="brandSelectionForm.brand"
          :form-id="BOOK_AN_APPOINTMENT_FORM_ID"
          @submit-book-an-appointment="handleStepProgression"
        />
        <BookAnAppointmentThankYou v-if="step === 2" />
      </template>
    </OrganismsSidebarSlide>
  </div>
</template>
